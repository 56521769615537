import React, { useContext, useEffect, useState } from "react";
import { Box, Container, Typography, CircularProgress } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import NewGradebookTable from "./NewGradebookTable";
import { gradebookInner, gradebookEmptyWeek } from "./NewGradebook.styles";
import { subjectContainer } from "../../../views/Subject.styles";
import newGradebookService from "../../../service/newGradebookService";
import { loader } from "../../sharedStyles";
import { QuickBarContext } from "../../../context/QuickBarContext";

export default function NewGradebook() {
  const subjectId = useParams().subject_id;
  const [searchParams, setSearchParams] = useSearchParams();
  const [students, setStudents] = useState([]);
  const [studentsWithoutAssignments, setStudentsWithoutAssignments] = useState(
    []
  );
  const [assignmentDates, setAssignmentDates] = useState([]);
  const [grades, setGrades] = useState({});
  const [gradesBeforeSave, setGradesBeforeSave] = useState({});
  const [categories, setCategories] = useState({});
  const [categoryScores, setCategoryScores] = useState({});
  const [totalScores, setTotalScores] = useState({});
  const [loading, setLoading] = useState(false);
  const [ungradedOnly, setUngradedOnly] = useState(false);
  const quickbarContext = useContext(QuickBarContext);

  const klassesIds = searchParams.get("classes") || null;

  const noCategoryKey = "No Category";

  const renderTheStudents = (dbAssignmentStudents) => {
    const studentsWithoutAssignmentsMap = new Map();
    let emptyCategories = [];

    if (dbAssignmentStudents.assigned_dates) {
      setAssignmentDates(
        dbAssignmentStudents.assigned_dates.sort((a, b) => {
          const dateA = new Date(a.assignedDate);
          const dateB = new Date(b.assignedDate);

          return dateA - dateB;
        })
      );
    }

    if (dbAssignmentStudents.students_with_assignments) {
      setStudents(dbAssignmentStudents.students_with_assignments);
    }

    if (dbAssignmentStudents.empty_categories) {
      emptyCategories = dbAssignmentStudents.empty_categories;
    }

    if (dbAssignmentStudents.grades) {
      setGrades(dbAssignmentStudents.grades);
      setGradesBeforeSave(dbAssignmentStudents.grades);
    }

    if (dbAssignmentStudents.students_without_assignments) {
      dbAssignmentStudents.students_without_assignments.forEach((aws) => {
        aws.klasses.forEach((k) => {
          const { klasses, ...rest } = aws;
          const studentWithoutAssignmentKey = `${aws.id}-${k.id}`;
          rest.klass = k;
          studentsWithoutAssignmentsMap.set(studentWithoutAssignmentKey, rest);
          k.klass_categories.forEach((ok) => emptyCategories.push(ok.category));
        });
      });

      setStudentsWithoutAssignments([
        ...studentsWithoutAssignmentsMap.values(),
      ]);
    }

    if (
      dbAssignmentStudents.category_scores &&
      klassesIds?.split(",").length === 1
    ) {
      const categoriesScoreMap = new Map();
      const categoriesMap = new Map();
      const categoriesArray = [];

      Object.values(dbAssignmentStudents.category_scores)
        .filter((cs) => cs.klass_id === Number(klassesIds?.split(",")[0]))
        .forEach((score) => {
          if (score.category === null) {
            categoriesMap.set(noCategoryKey, noCategoryKey);
            categoriesScoreMap.set(
              `${score.student_id}-${score.klass_id}-${noCategoryKey}`,
              score.category_score
            );
          } else {
            categoriesMap.set(score.category, score.category);
            categoriesScoreMap.set(
              `${score.student_id}-${score.klass_id}-${score.category}`,
              score.category_score
            );
          }
        });

      categoriesArray.push(...categoriesMap.values(), ...emptyCategories);
      categoriesArray.sort();
      const uniqueCategories = [...new Set(categoriesArray)].filter(
        (category) => category !== null
      );

      setCategories(uniqueCategories);
      setCategoryScores(categoriesScoreMap);
    } else {
      setCategories([]);
      setCategoryScores(new Map());
    }

    setTotalScores(dbAssignmentStudents.total_scores);
  };

  const getAssignmentStudents = async () => {
    setLoading(true);
    newGradebookService
      .fetchAssignmentStudents({
        params: {
          date: searchParams.get("date"),
          klasses_ids: klassesIds?.split(",").map((k) => Number(k)),
          subject_id: subjectId,
          term_id: searchParams.get("term"),
          ungraded_only: ungradedOnly,
        },
      })
      .then((dbAssignmentStudents) => {
        renderTheStudents(dbAssignmentStudents);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderStudents = [...students, ...studentsWithoutAssignments].filter(
    (allStudents, index, array) =>
      array.findIndex(
        (s) => s.klass.id === allStudents.klass.id && s.id === allStudents.id
      ) === index
  );

  renderStudents.sort((a, b) =>
    `${a.klass.abbreviation}${a.last_name}${a.first_name}${a.middle_name}`.localeCompare(
      `${b.klass.abbreviation}${b.last_name}${b.first_name}${a.middle_name}`
    )
  );

  useEffect(() => {
    if (
      !quickbarContext.assignmentDrawer &&
      searchParams.get("added_assignment") === "true"
    ) {
      searchParams.delete("added_assignment");
      setSearchParams(searchParams);
      getAssignmentStudents();
    }
  }, [
    klassesIds,
    subjectId,
    searchParams.get("added_assignment"),
    quickbarContext.assignmentDrawer,
  ]);

  useEffect(() => {
    getAssignmentStudents();
  }, [klassesIds, subjectId, searchParams.get("term"), ungradedOnly]);

  return (
    <Box sx={subjectContainer}>
      {loading ? (
        <CircularProgress sx={loader} size={100} />
      ) : (
        <Container maxWidth="xl" sx={gradebookInner}>
          {renderStudents && renderStudents.length > 0 ? (
            <NewGradebookTable
              assignmentDates={assignmentDates}
              renderStudents={renderStudents}
              grades={grades}
              gradesBeforeSave={gradesBeforeSave}
              categories={categories}
              categoryScore={categoryScores}
              totalScores={totalScores}
              setGrades={setGrades}
              setCategoryScores={setCategoryScores}
              setTotalScores={setTotalScores}
              noCategoryKey={noCategoryKey}
              ungradedOnly={ungradedOnly}
              setUngradedOnly={setUngradedOnly}
            />
          ) : (
            <Box sx={gradebookEmptyWeek}>
              <Typography>No assignments for this week.</Typography>
            </Box>
          )}
        </Container>
      )}
    </Box>
  );
}
