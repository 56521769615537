import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  font16,
  font20,
  font32,
  formButtons,
  formCheckBox,
  formContainer,
  formHeader,
  formTitle,
  mb10,
  mb45,
  mt25,
} from "../sharedStyles";
import {
  reportCardDetail,
  reportCardDrawer,
  reportCardGradeBox,
  reportCardInfoContainer,
  reportCardSkillsContainer,
} from "./ReportCardTKKDrawer.styles";
import {
  reportCardCalculationsTableCellBold,
  reportCardDetailLabel,
  reportCardDetailRadioContainer,
} from "./ReportCardOneEightDrawer.styles";
import termGradeService from "../../service/termGradeService";
import formattedGrade from "../../utils/formattedGrade";
import CalculationsRow from "../Subject/TermGrade/TermGradeDetail/CalculationsRow";
import { overrideGradeContainer } from "../Subject/TermGrade/TermGradeDetail/OverrideGrade.styles";

export default function ReportCardOneEightDrawer({
  student,
  teachers,
  klass,
  subjects,
  skills,
  termGrades,
  handleDrawerClose,
  specialMarks,
  term,
}) {
  const teacher = teachers.find((t) => t.id === klass.teacher_id);
  const subject = subjects.find((s) => s.id === klass.subject_id);
  let termGrade = {};
  if (student.id in termGrades && klass.id in termGrades[student.id]) {
    termGrade = termGrades[student.id][klass.id];
  }
  const subjectSkills = skills.skills_by_subject[subject.id];
  const studentSkills = skills.student_skills_by_student[student.id][klass.id];
  const comment = termGrade.comment || "";
  const instructionLevel = termGrade.instructional_level || null;
  const [categoryScores, setCategoryScores] = useState({});

  const getScores = async () => {
    const studentId = student.id;
    const klassesId = klass.id;
    const response = await termGradeService.fetchCategoryScores(studentId, {
      params: {
        klass_id: klassesId,
      },
    });

    if (response.assignment_student) {
      setCategoryScores(response.category_scores);
    }
  };

  const letterGrade = () =>
    formattedGrade(
      termGrade.calculated_grade,
      100,
      klass?.grading_scale?.marking_codes,
      specialMarks
    );

  const postedLetterGrade = () =>
    formattedGrade(
      termGrade.posted_grade,
      100,
      klass?.grading_scale?.marking_codes,
      specialMarks
    );

  useEffect(() => {
    getScores();
  }, []);

  const renderGradeBox = (skillID) => (
    <Box sx={reportCardGradeBox}>
      {studentSkills && studentSkills[skillID]
        ? studentSkills[skillID].score
        : ""}
    </Box>
  );
  return (
    <Box sx={reportCardDrawer}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>{klass.name}</Typography>
        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawerClose()}>
            Close
          </Button>
        </Stack>
      </Stack>

      <Box sx={formContainer}>
        <Box sx={reportCardDetail}>
          <Typography sx={font32}>
            {student?.first_name} {student?.last_name}
          </Typography>
          <Box sx={reportCardInfoContainer}>
            <Typography sx={font20}>{klass?.name}</Typography>
            <Typography sx={font20}>
              {teacher?.first_name} {teacher?.last_name}
            </Typography>
          </Box>
        </Box>
        <Box sx={mb45}>
          <Box sx={mt25}>
            <TextField
              id="comment"
              label="Report Card Comment"
              fullWidth
              type="text"
              multiline
              rows={4}
              disabled
              value={comment}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={font20}>Instructional Level</Typography>
          <FormControl>
            <RadioGroup row>
              <FormControlLabel
                value="_none"
                control={
                  <Radio checked={instructionLevel === "_none"} disabled />
                }
                label={
                  <Box sx={reportCardDetailLabel}>
                    <Typography>None</Typography>
                  </Box>
                }
                sx={reportCardDetailRadioContainer}
              />
              <FormControlLabel
                value="x"
                control={<Radio checked={instructionLevel === "x"} disabled />}
                sx={reportCardDetailRadioContainer}
                label={
                  <Box sx={reportCardDetailLabel}>
                    <Typography>X</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="y"
                control={<Radio checked={instructionLevel === "y"} disabled />}
                sx={reportCardDetailRadioContainer}
                label={
                  <Box sx={reportCardDetailLabel}>
                    <Typography>Y</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="ya"
                control={<Radio checked={instructionLevel === "ya"} disabled />}
                sx={reportCardDetailRadioContainer}
                label={
                  <Box sx={reportCardDetailLabel}>
                    <Typography>YA</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="z"
                control={<Radio checked={instructionLevel === "z"} disabled />}
                sx={reportCardDetailRadioContainer}
                label={
                  <Box sx={reportCardDetailLabel}>
                    <Typography>Z</Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <Grid container mt={4}>
            <Grid item sm={7}>
              <Typography sx={{ ...font20, ...mb10 }}>Class Grades</Typography>
              <Box sx={mb45}>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {categoryScores.length > 0 &&
                        categoryScores.map((cs) => (
                          <CalculationsRow
                            key={cs.id}
                            category={cs}
                            isLastRow={
                              categoryScores[categoryScores.length - 1] === cs
                            }
                            markingCodes={klass.grading_scale.marking_codes}
                            specialMarks={specialMarks}
                            isPassFail={klass.is_pass_fail}
                            viewTotalOverHundred
                          />
                        ))}
                      <TableRow>
                        <TableCell sx={reportCardCalculationsTableCellBold}>
                          {term.name} Total
                        </TableCell>
                        <TableCell sx={reportCardCalculationsTableCellBold}>
                          {termGrade.calculated_grade}%{" "}
                          {termGrade.calculated_grade ? letterGrade() : ""}
                        </TableCell>
                        <TableCell sx={reportCardCalculationsTableCellBold} />
                      </TableRow>
                      <TableRow>
                        <TableCell sx={reportCardCalculationsTableCellBold}>
                          Published Total
                        </TableCell>
                        <TableCell sx={reportCardCalculationsTableCellBold}>
                          {termGrade.posted_grade}%{" "}
                          {termGrade.posted_grade ? postedLetterGrade() : ""}
                        </TableCell>
                        <TableCell sx={reportCardCalculationsTableCellBold} />
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <FormControl fullWidth sx={formCheckBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={termGrade.overridden || false}
                      />
                    }
                    label={
                      <Box>
                        <Typography>Override Grade</Typography>
                      </Box>
                    }
                    disabled
                  />
                </FormControl>
                {termGrade.overridden && (
                  <Box>
                    <Stack direction="row" sx={overrideGradeContainer}>
                      <Typography>Publish final grade as</Typography>
                      <TextField
                        id="grade"
                        required
                        label="Grade"
                        disabled
                        value={
                          termGrade.overridden ? termGrade.posted_grade : ""
                        }
                        sx={{ marginLeft: "15px", width: "80px" }}
                      />
                    </Stack>
                    <Box>
                      <TextField
                        id="comment"
                        label="Override Comment"
                        fullWidth
                        type="text"
                        multiline
                        disabled
                        rows={4}
                        value={
                          termGrade.overridden ? termGrade.override_comment : ""
                        }
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item sm={5}>
              <Typography sx={{ ...font20, ...mb10 }}>Skill Grades</Typography>
              {subjectSkills ? (
                Object.values(subjectSkills).map(
                  (skill) =>
                    skill.grading_scale_id === klass.grading_scale_id && (
                      <Box key={skill.id} sx={reportCardSkillsContainer}>
                        {renderGradeBox(skill.id)}
                        <Box sx={font16}>{skill.name}</Box>
                      </Box>
                    )
                )
              ) : (
                <Box>This subject does not have skills associated</Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
