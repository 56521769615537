import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useParams } from "react-router-dom";
import {
  CheckCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
  RestoreSharp,
} from "@mui/icons-material";
import teachersService from "../../../service/staffsService";
import ErasableTextField from "../../ErasableTextField";
import {
  accountVerticalContainer,
  profilePicContainer,
  academicsImg,
  fieldsContainer,
} from "./GeneralInfoProfile.style";
import lookupService from "../../../service/lookupService";
import { danger } from "../../sharedStyles";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import StaffType from "../../../utils/constants/staffTypeEnum";
import moment from "../../../utils/constants/momentConfig";
import dateTimeFormats from "../../../utils/constants/dateTimeFormats";
import withdrawalReasonsService from "../../../service/withdrawalReasonsService";

export default function GeneralInfoProfile({
  student,
  setEnrollmentsVersionOpen,
}) {
  const [gradeLevels, setGradeLevels] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [teachersLoaded, setTeachersLoaded] = useState(false);
  const [enrolledDate, setEnrolledDate] = React.useState(
    student.enrollment?.date || null
  );
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();
  const [profilePictureSrc, setProfilePictureSrc] = useState(
    "/assets/default_avatar.jpg"
  );
  const [financialHolds, setFinancialHolds] = useState([]);
  const [studentStatuses, setStudentStatuses] = useState([]);
  const [studentNextYearStatuses, setStudentNextYearStatuses] = useState([]);
  const [withdrawalReasons, setWithdrawalReasons] = useState([]);
  const { hasPermission } = useContext(PermissionsContext);
  const params = useParams();

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_PROFILE,
    PERMISSION_TYPES.MANAGE
  );

  const renderSelectOptions = (options) =>
    options.map((option) => (
      <MenuItem value={option.id} key={option.id}>
        {`${option.first_name} ${option.last_name}`}
      </MenuItem>
    ));

  const loadTeachers = async () => {
    const response = await teachersService.fetchAllStaffs({
      params: { school_id: params.school_id, staff_type: StaffType.TEACHER },
    });
    setTeachers(response);
    setTeachersLoaded(true);
  };

  useEffect(() => {
    setValue("enrollment.id", student.enrollment?.id);
    (async () => {
      await loadTeachers();
    })();
  }, []);

  useEffect(() => {
    if (student?.image_url) {
      setProfilePictureSrc(student.image_url.url);
    }
    if (student?.enrollment?.date) {
      setEnrolledDate(moment.utc(student.enrollment.date));
    }
    Promise.all([
      lookupService.fetchFinancialHolds(),
      lookupService.fetchGradeLevels(),
      lookupService.fetchStudentStatuses(),
      lookupService.fetchStudentNextYearStatuses(),
      withdrawalReasonsService.fetchAll(),
    ]).then((response) => {
      setFinancialHolds(response[0].data.data);
      setGradeLevels(response[1].data.data);
      setStudentStatuses(response[2].data.data);
      setStudentNextYearStatuses(response[3].data.data);
      setWithdrawalReasons(response[4].data);
      trigger();
    });
  }, [student]);

  const renderEnrollment = () => {
    if (student.enrollment?.is_enrolled) {
      return (
        <>
          <CheckCircleOutlineOutlined fontSize="large" color="success" />
          <Typography variant="h5" ml={2.5}>
            Enrolled
          </Typography>
        </>
      );
    }
    return (
      <>
        <RemoveCircleOutlineOutlined fontSize="large" color="error" />
        <Typography variant="h5" ml={2.5} color="error">
          Withdrawn
        </Typography>
      </>
    );
  };
  return (
    <Grid container item marginY={5} xs={12} sm={12} md={12}>
      <Grid item xs={12} sm={12} md={9} pl={3} sx={accountVerticalContainer}>
        <Grid container item gap={2} rowSpacing={3} sx={fieldsContainer}>
          <Grid container item xs={12} gap={2}>
            <Grid item xs={12} sm={6} md={3} alignItems="center" display="flex">
              {renderEnrollment()}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DesktopDatePicker
                id="enrolled_date"
                label="Date"
                inputFormat="MM/DD/YYYY"
                disabled={!managePermission}
                value={moment
                  .utc(enrolledDate)
                  .format(dateTimeFormats.MMDDYYYY)
                  .toString()}
                onChange={(date) => {
                  if (date) {
                    setEnrolledDate(date);
                    setValue("enrollment.date", date.format("MM/DD/YYYY"), {
                      shouldDirty: true,
                    });
                  }
                  trigger("enrollment.date");
                }}
                renderInput={(renderParams) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      {...renderParams}
                      required={getValues("student_status") === "Enrolled"}
                      error={Boolean(errors.enrollment?.date)}
                      {...register("enrollment.date", {
                        required: {
                          value: getValues("student_status") === "Enrolled",
                          message: "This field is required.",
                        },
                        pattern: {
                          value:
                            /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                          message: "Invalid date format.",
                        },
                      })}
                    />
                    <RestoreSharp
                      sx={{ ml: 3, cursor: "pointer" }}
                      onClick={() => {
                        setEnrollmentsVersionOpen(true);
                      }}
                    />
                  </Box>
                )}
              />
              {errors.enrollment?.date && (
                <Typography sx={danger}>
                  {errors.enrollment?.date?.message}
                </Typography>
              )}
            </Grid>
            {!student.enrollment?.is_enrolled && (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Withdrawal Reason</InputLabel>
                  <Select
                    defaultValue={
                      student.enrollment?.withdrawal_reason_id || ""
                    }
                    label="Withdrawal Reason"
                    {...register("withdrawal_reason_id")}
                    disabled={!managePermission}
                  >
                    <MenuItem value="" disabled>
                      Withdrawal Reason
                    </MenuItem>
                    {withdrawalReasons
                      ?.filter((reason) => reason.active)
                      ?.map((reason) => (
                        <MenuItem key={reason.id} value={reason.id}>
                          {reason.description}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid item xs={11} borderBottom="1px #BFC7CA solid" />
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Admission Status</InputLabel>
              <Select
                defaultValue={student.student_status || ""}
                label="Admission Status"
                {...register("student_status")}
                disabled={!managePermission}
              >
                {studentStatuses.map((status) => (
                  <MenuItem key={status.id} value={status.name}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Next Year&apos;s Status</InputLabel>
              <Select
                defaultValue={student.student_next_year_status || ""}
                label="Next Year's Status"
                {...register("student_next_year_status")}
                disabled={!managePermission}
              >
                <MenuItem value="">-- No status</MenuItem>
                {studentNextYearStatuses.map((status) => (
                  <MenuItem key={status.id} value={status.name}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <FormControl fullWidth>
              <InputLabel>Next Year&apos;s Grade Level</InputLabel>
              <Select
                defaultValue={student.student_next_year_grade_level || ""}
                label="Next Year's Grade Level"
                {...register("student_next_year_grade_level")}
                disabled={!managePermission}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {gradeLevels.map((gradelevel) => (
                  <MenuItem key={gradelevel.id} value={gradelevel.name}>
                    {gradelevel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>Financial Hold</InputLabel>
              <Select
                defaultValue={student.financial_hold || ""}
                label="Financial Hold"
                {...register("financial_hold")}
                disabled={!managePermission}
              >
                {financialHolds.map((financialHold) => (
                  <MenuItem key={financialHold.id} value={financialHold.name}>
                    {financialHold.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={9}>
            <FormControl fullWidth>
              <TextField
                id="school"
                label="School"
                defaultValue={student.school.name}
                fullWidth
                disabled
              />
            </FormControl>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ErasableTextField
                id="counselor_name"
                label="Counselor"
                defaultValue={student.counselor_name}
                fullWidth
                disabled={!managePermission}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {teachersLoaded && (
                <FormControl fullWidth>
                  <InputLabel id="homeroom_teacher_label">
                    Homeroom Teacher
                  </InputLabel>
                  <Select
                    labelId="homeroom_teacher_label"
                    id="homeroom_teacher"
                    label="Homeroom Teacher"
                    defaultValue={student?.homeroom_teacher_id || ""}
                    {...register(`homeroom_teacher_id`)}
                    disabled={!managePermission}
                  >
                    <MenuItem disabled value="">
                      Homeroom Teacher
                    </MenuItem>
                    {renderSelectOptions(teachers)}
                  </Select>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <ErasableTextField
                id="locker_number"
                label="Locker Number"
                defaultValue={student.locker_number}
                fullWidth
                disabled={!managePermission}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={11} md={3}>
        <Grid
          item
          xs={12}
          ml={{ sx: "10%", sm: "5%" }}
          sx={profilePicContainer}
        >
          <img alt="profile" src={profilePictureSrc} style={academicsImg} />
        </Grid>
      </Grid>
    </Grid>
  );
}
