import React, { useContext, useEffect, useState } from "react";
import { useSearchParams, useParams, useOutletContext } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { loader, textCenter, pt30 } from "../../sharedStyles";
import StudentsTable from "./StudentsTable";
import studentsService from "../../../service/studentsService";
import { QuickBarContext } from "../../../context/QuickBarContext";
import schoolsService from "../../../service/schoolsService";
import schoolYearApplicationService from "../../../service/schoolYearApplicationService";
import {
  EnrollmentDateDialog,
  GradeLevelDialog,
  HomeroomTeacherDialog,
} from "../../../views/StudentList";
import {
  editButton,
  filterButton,
  filtersBorder,
} from "../../../views/StudentList.style";

export default function EnrolledStudents({ isCompact = false }) {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = React.useState(
    searchParams.get("direction") || "asc"
  );
  const [orderBy, setOrderBy] = React.useState(
    searchParams.get("sort") || "student"
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [students, setStudents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(searchParams.get("count") || 25);
  const quickBarContext = useContext(QuickBarContext);
  const [, , query, setQuery] = useOutletContext();
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [currentSchool, setCurrentSchool] = useState({});
  const params = useParams();
  const schoolId = params.school_id;
  const [gradeLevel, setGradeLevel] = useState("all");
  const [levels, setLevels] = useState(["all"]);
  const [allStudentIds, setAllStudentIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [enrollmentDateDialogOpen, setEnrollmentDateDialogOpen] =
    useState(false);
  const [homeroomTeacherDialogOpen, setHomeroomTeacherDialogOpen] =
    useState(false);
  const [gradeLevelDialogOpen, setGradeLevelDialogOpen] = useState(false);
  const [totalStudents, setTotalStudents] = React.useState(0);

  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    handleClose();
    switch (option) {
      case "enrollment_date":
        setEnrollmentDateDialogOpen(true);
        break;
      case "homeroom_teacher":
        setHomeroomTeacherDialogOpen(true);
        break;
      case "grade_level":
        setGradeLevelDialogOpen(true);
        break;
      default:
    }
  };

  const getAdvancedRegistrations = async () => {
    const response = await studentsService.admissionProcessStudents(
      searchParams.get("school_year")
    );
    if (response.data) {
      setRegisteredStudents(response.data);
    }
  };

  const getBasicRegistrations = async () => {
    const response = await schoolYearApplicationService.fetchAll({
      params: {
        school_year_id: searchParams.get("school_year"),
        order,
        order_by: orderBy,
        query,
      },
    });
    if (response.data) {
      setRegisteredStudents(response.data.school_year_applications);
    }
  };

  const getSchool = async () => {
    const response = await schoolsService.fetchSchool(params.school_id);

    setCurrentSchool(response.data);

    if (response.data.admission_version_advanced) {
      getAdvancedRegistrations();
      return;
    }

    getBasicRegistrations();
  };

  useEffect(() => {
    setPage(1);
    searchParams.set("page", 1);
    setSearchParams(searchParams);
    if (searchParams.get("school_year")) {
      getSchool();
    }
  }, [searchParams.get("school_year"), query]);

  const fetchStudentList = async () => {
    setLoading(true);
    const response = await studentsService.enrollments({
      params: {
        school_id: schoolId,
        page,
        orderBy,
        order,
        query,
        count,
        schoolYearId: searchParams.get("school_year"),
        gradeLevel: gradeLevel !== "all" ? gradeLevel : undefined,
      },
    });
    const queryPage = searchParams.get("page") || 1;
    const lastPage = response.pagination.total_pages;
    setLevels([...new Set(response.pagination.grade_levels)]);
    const currentPage = queryPage > lastPage ? lastPage : queryPage;
    setStudents(response.data);
    setAllStudentIds(response.all_student_ids);
    setTotalStudents(response.pagination.total_count);
    setTotalPages(lastPage);
    setPage(currentPage);
    setLoading(false);
  };

  const handleStudentFilterClick = (value) => {
    if (value === gradeLevel) return;
    setGradeLevel(value);
    setSelected([]);
  };

  const handleEnrollmentDateConfirm = async (newDate) => {
    try {
      await studentsService.bulkEditStudents(
        selected,
        "enrollment_date",
        newDate,
        searchParams.get("school_year")
      );
      fetchStudentList();
      setSelected([]);
    } catch (error) {
      console.error("Failed to update enrollment date:", error);
    }
    setEnrollmentDateDialogOpen(false);
  };

  const handleHomeroomTeacherConfirm = async (newTeacherId) => {
    try {
      await studentsService.bulkEditStudents(
        selected,
        "homeroom_teacher_id",
        newTeacherId
      );
      fetchStudentList();
    } catch (error) {
      console.error("Failed to update homeroom teacher:", error);
    }
    setHomeroomTeacherDialogOpen(false);
  };

  const handleGradeLevelConfirm = async (newGradeLevel) => {
    try {
      await studentsService.bulkEditStudents(
        selected,
        "grade_level",
        newGradeLevel
      );
      fetchStudentList();
      setSelected([]);
    } catch (error) {
      console.error("Failed to update grade level:", error);
    }
    setGradeLevelDialogOpen(false);
  };

  const getStudents = () => {
    fetchStudentList();
    setOrder(searchParams.get("direction") || "asc");
    setOrderBy(searchParams.get("sort") || "student");
    setQuery(searchParams.get("query"));
  };

  useEffect(() => {
    if (searchParams.get("school_year")) getStudents();
  }, [
    searchParams.get("page"),
    searchParams.get("direction"),
    searchParams.get("sort"),
    searchParams.get("query"),
    searchParams.get("count"),
    searchParams.get("school_year"),
    gradeLevel,
  ]);

  const syncStudents = () => {
    studentsService
      .syncStudents({
        school_id: schoolId,
        school_year_id: searchParams.get("school_year"),
      })
      .then(() => {
        getStudents();
        quickBarContext.cleanSyncStudents();
      })
      .catch(() => {
        quickBarContext.cleanSyncStudents();
      });
  };

  useEffect(() => {
    if (quickBarContext.syncStudents) {
      syncStudents();
    }
  }, [quickBarContext.syncStudents]);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  return (
    <div>
      <Grid item xs={7} sx={filtersBorder}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Button
            sx={filterButton(gradeLevel === "all")}
            size="small"
            onClick={() => handleStudentFilterClick("all")}
          >
            All
          </Button>
          {levels.map((level) => (
            <Button
              sx={filterButton(gradeLevel === level)}
              size="small"
              key={level}
              onClick={() => handleStudentFilterClick(level)}
            >
              {level}
            </Button>
          ))}
          {selected.length > 0 && (
            <Box sx={editButton}>
              <Button
                size="small"
                onClick={handleEditClick}
                startIcon={<Edit />}
              >
                Edit
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleOptionSelect("enrollment_date")}>
                  Enrollment Date
                </MenuItem>
                <MenuItem
                  onClick={() => handleOptionSelect("homeroom_teacher")}
                >
                  Homeroom Teacher
                </MenuItem>
                <MenuItem onClick={() => handleOptionSelect("grade_level")}>
                  Grade Level
                </MenuItem>
              </Menu>
              <EnrollmentDateDialog
                open={enrollmentDateDialogOpen}
                onClose={() => setEnrollmentDateDialogOpen(false)}
                onConfirm={handleEnrollmentDateConfirm}
                selectedCount={selected.length}
              />
              <HomeroomTeacherDialog
                open={homeroomTeacherDialogOpen}
                onClose={() => setHomeroomTeacherDialogOpen(false)}
                onConfirm={handleHomeroomTeacherConfirm}
                selectedCount={selected.length}
              />
              <GradeLevelDialog
                open={gradeLevelDialogOpen}
                onClose={() => setGradeLevelDialogOpen(false)}
                onConfirm={handleGradeLevelConfirm}
                selectedCount={selected.length}
              />
              {`(${selected.length} students selected. `}{" "}
              <Box
                sx={{ display: "inline", color: "#2196F3", cursor: "pointer" }}
                onClick={() => setSelected(allStudentIds)}
              >
                &nbsp;Select all filtered students.
              </Box>
              )
            </Box>
          )}
        </Box>
        <Box sx={{ marginLeft: "auto", paddingLeft: "16px" }}>
          <span style={{ fontSize: "14px" }}>
            <span style={{ fontWeight: "500" }}>{totalStudents} </span>students
          </span>
        </Box>
      </Grid>
      {students.length > 0 ? (
        <StudentsTable
          isCompact={isCompact}
          students={students}
          setStudents={setStudents}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          selected={selected}
          order={order}
          orderBy={orderBy}
          setSelected={setSelected}
          setPage={setPage}
          totalPages={totalPages}
          setOrderBy={setOrderBy}
          setOrder={setOrder}
          page={page}
          count={count}
          setCount={setCount}
          totalStudents={totalStudents}
          registeredStudents={registeredStudents}
          enrolledStudents
          currentSchool={currentSchool}
        />
      ) : (
        <Typography sx={[textCenter, pt30]}>No students found.</Typography>
      )}
    </div>
  );
}
