import React from "react";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import {
  font16,
  font20,
  font26,
  font32,
  formButtons,
  formContainer,
  formHeader,
  formTitle,
  mb10,
  mb45,
  mt25,
} from "../sharedStyles";
import {
  reportCardDetail,
  reportCardDrawer,
  reportCardGradeBox,
  reportCardInfoContainer,
  reportCardSkillsContainer,
} from "./ReportCardTKKDrawer.styles";

export default function ReportCardTKKDrawer({
  student,
  teachers,
  klass,
  subjects,
  skills,
  handleDrawerClose,
  termGrades,
}) {
  const teacher = teachers.find((t) => t.id === klass.teacher_id);
  const subject = subjects.find((s) => s.id === klass.subject_id);
  const subjectSkills = skills.skills_by_subject[subject.id];
  const studentSkills = skills.student_skills_by_student[student.id][klass.id];
  const comment = termGrades[student.id][klass.id].comment || "";

  const renderGradeBox = (skillID) => (
    <Box sx={reportCardGradeBox}>
      {studentSkills && studentSkills[skillID]
        ? studentSkills[skillID].score
        : ""}
    </Box>
  );

  return (
    <Box sx={reportCardDrawer}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>{subject.name}</Typography>
        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawerClose()}>
            Close
          </Button>
        </Stack>
      </Stack>

      <Box sx={formContainer}>
        <Box sx={reportCardDetail}>
          <Typography sx={font32}>
            {student?.first_name} {student?.last_name}
          </Typography>
          <Box sx={reportCardInfoContainer}>
            <Typography sx={font20}>{klass?.name}</Typography>
            <Typography sx={font20}>
              {teacher?.first_name} {teacher?.last_name}
            </Typography>
          </Box>
        </Box>
        <Box sx={mb45}>
          <Box sx={mt25}>
            <TextField
              id="comment"
              label="Report Card Comment"
              fullWidth
              type="text"
              multiline
              rows={4}
              disabled
              value={comment}
            />
          </Box>
        </Box>
        <Box>
          <Typography sx={{ ...font26, ...mb10 }}>Skill Grades</Typography>
          {subjectSkills ? (
            Object.values(subjectSkills).map(
              (skill) =>
                skill.grading_scale_id === klass.grading_scale_id && (
                  <Box key={skill.id} sx={reportCardSkillsContainer}>
                    {renderGradeBox(skill.id)}
                    <Box sx={font16}>{skill.name}</Box>
                  </Box>
                )
            )
          ) : (
            <Box>This subject does not have skills associated</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
